import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DragDropContext } from '@hello-pangea/dnd'
import { isEmpty } from 'lodash'
import useRankOneOnOneMeeting from 'src/queries/virtualPortal/useRankOneOnOneMeeting'
import useFetchOneOnOneMeetingWishlist from 'src/queries/virtualPortal/useFetchOneOnOneMeetingWishlist'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import DraggableList from './DraggableList'
import WishlistTableHeader from './WishlistTableHeader'
import FooterButtons from '../components/FooterButtons'
import UserDetailsModal from '../components/UserDetailsModal'

const WishlistRank = ({ createMeeting, deleteMeeting }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { wishlistContacts, potentialWishlistContacts } = useFetchOneOnOneMeetingWishlist()
  const { mutate } = useRankOneOnOneMeeting()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [wishlistContactsState, setWishlistContactsState] = useState(wishlistContacts)
  const [potentialWishlistContactsState, setPotentialWishlistContactsState] = useState(potentialWishlistContacts)

  useEffect(() => {
    setWishlistContactsState(wishlistContacts)
    setPotentialWishlistContactsState(potentialWishlistContacts)
  }, [wishlistContacts, potentialWishlistContacts])

  const handleOnDragEnd = (result) => {
    const { source, destination } = result

    // If no destination, exit early
    if (!destination) return

    // Copy the current state
    const newWishlistContacts = Array.from(wishlistContactsState)
    const newPotentialWishlistContacts = Array.from(potentialWishlistContactsState)

    // Moving from potentialWishlistContacts to wishlistContacts
    if (destination.droppableId === 'wishlistContacts' && source.droppableId === 'potentialWishlistContacts') {
      const [movedItem] = newPotentialWishlistContacts.splice(source.index, 1)
      movedItem.added = true
      movedItem.on_other_wishlist = true
      newWishlistContacts.splice(destination.index, 0, movedItem)
    } else if (destination.droppableId === 'wishlistContacts' && source.droppableId === 'wishlistContacts') {
      const [reorderedItem] = newWishlistContacts.splice(source.index, 1)
      newWishlistContacts.splice(destination.index, 0, reorderedItem)
    } else if (destination.droppableId === 'potentialWishlistContacts' && source.droppableId === 'potentialWishlistContacts') {
      const [reorderedItem] = newPotentialWishlistContacts.splice(source.index, 1)
      newPotentialWishlistContacts.splice(destination.index, 0, reorderedItem)
    } else if (destination.droppableId === 'potentialWishlistContacts' && source.droppableId === 'wishlistContacts') {
      const [movedItem] = newWishlistContacts.splice(source.index, 1)
      movedItem.added = false
      movedItem.on_other_wishlist = false
      newPotentialWishlistContacts.splice(destination.index, 0, movedItem)
    }

    // Update state after operations
    setWishlistContactsState(newWishlistContacts)
    setPotentialWishlistContactsState(newPotentialWishlistContacts)
  }

  const openModal = useCallback((user) => {
    setSelectedUser(user)
    setIsModalOpen(true)
  }, [])

  const onClick = useCallback((wishlistId, type) => {
    if (type === 'delete') {
      deleteMeeting(wishlistId)
    } else if (type === 'create') {
      createMeeting(wishlistId, 'true')
    }
  }, [deleteMeeting, createMeeting])

  const rank = useCallback(async (url) => {
    await mutate(wishlistContactsState)
    setIsSaving(false)
    navigate(url)
  }, [wishlistContactsState])

  const onSubmit = useCallback(() => {
    setIsSaving(true)
    const url = `${location.pathname.replace('wishlist_rank', 'availability')}${location.search}`
    rank(url)
  }, [location, rank])

  const onPrev = useCallback(() => {
    navigate(-1)
    rank()
  }, [])

  if (isEmpty(wishlistContactsState)) {
    const { token } = useParams()
    return (
      <>
        <WishlistTableHeader />
        <p>You have no selections to rank. Please go to the
          <a href={`/${token}/wishlist/wizard?wizard_from=web`}>&quot;My Preferences&quot;</a> page to update your meeting preferences.
        </p>
      </>
    )
  }

  return (
    <div>
      <div className={generalStyles.mt30}>
        <h3 style={{ fontWeight: 'normal' }} className={fontStyles.h2alternate2bolddark}>Rank your preferences for peer-to-peer meetings</h3>
        <p>Please press and hold the arrows to move and reorder your ranking preferences.</p>
        <p>If you select &apos;Remove,&apos; the person is removed from your list (They will not be able to see that you blocked them).
          They can be added back in by returning to the &apos;My Preferences&apos; page.</p>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <DraggableList
          contacts={wishlistContactsState}
          id='wishlistContacts'
          openModal={openModal}
          onClick={onClick}
        />
        {potentialWishlistContactsState.length > 0 &&
        <div>
          <div style={{ marginTop: 90 }}>
            <h3 style={{ fontWeight: 'normal' }} className={fontStyles.h2alternate2bolddark}>Top Listing</h3>
            <p>This list shows executives who have ranked you among their top peers to meet with. If you would like to meet with them, you can use the arrows to drag the lines up to your ranked list above.</p>
            <p>If you select &quot;Block,&quot; that person will not be able to schedule a meeting with you.</p>
          </div>
          <DraggableList
            contacts={potentialWishlistContactsState}
            id='potentialWishlistContacts'
            openModal={openModal}
            onClick={onClick}
          />
        </div>
        }
      </DragDropContext>
      <UserDetailsModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} user={selectedUser} />
      <FooterButtons isLoading={isSaving} onClick={onSubmit} onPrev={onPrev}/>
    </div>
  )
}

export default WishlistRank
