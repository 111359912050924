import { isMobile } from 'react-device-detect'

export const useRedirect = () => {
  const url = new URL(window.location.href)
  const search = new URLSearchParams(url.search)
  // testing locally
  // const mode = import.meta.env.DEV
  // FOR PRODUCTION TESTING BECAUSE SeCuRiTy
  if (isMobile && search.has('test')) {
    // if (isMobile) {
    const appScheme = import.meta.env.DEV ? 'exp' : 'my-evanta-app'
    const path = import.meta.env.DEV
      ? `127.0.0.1:8081/--${url.pathname}`
      : url.pathname
    const newPath = new URL(`${appScheme}://${path}`)
    newPath.search = search
    newPath.searchParams.append('fromWeb', true)
    window.location.href = newPath.href
  }
}
